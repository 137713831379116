<template>
  <div> идет загрузка ...</div>
</template>

<style scoped>
.hero-section {
  align-items: flex-start;
  background-image: linear-gradient(15deg, #0f4667 0%, #2a6973 150%);
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}

@media (min-width: 540px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .card-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

.card-spicker {
  list-style: none;
  position: relative;
}

.card-spicker:before {
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card__background {
  background-size: cover;
  background-position: center;
  border-radius: var(--spacing-l);
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  trsnsform: scale(1) translateZ(0);
  transition: filter 200ms linear,
  transform 200ms linear;
}

.card-spicker:hover .card__background {
  transform: scale(1.05) translateZ(0);
}

.card-grid:hover > .card:not(:hover) .card__background {
  filter: brightness(0.5) saturate(0) contrast(1.2) blur(30px);
}

.card__content {
  left: 10px;
  padding: var(--spacing-l);
  position: absolute;
  bottom: 0;
  color: white;
}

.card__category {
  color: var(--text-light);
  font-size: 0.7rem;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.card__heading {
  color: var(--text-lighter);
  font-size: 1.2rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}
</style>
<style scoped>
/* Variables */
/* Fonts */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,700);

/* Styling */
.timeline {
  margin: 4em auto;
  position: relative;
  max-width: 46em;
}

.timeline:before {
  background-color: black;
  content: '';
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}

.timeline-event {
  position: relative;
}

.timeline-event:hover .timeline-event-icon {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #04A27C;
}

.timeline-event:hover .timeline-event-thumbnail {
  -moz-box-shadow: inset 40em 0 0 0 #04A27C;
  -webkit-box-shadow: inset 40em 0 0 0 #04A27C;
  box-shadow: inset 40em 0 0 0 #04A27C;
}

.timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;
}

.timeline-event-copy h3 {
  font-size: 1.75em;
}

.timeline-event-copy h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}

.timeline-event-copy strong {
  font-weight: 700;
}

.timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}

.timeline-event-icon {
  -moz-transition: -moz-transform 0.2s ease-in;
  -o-transition: -o-transform 0.2s ease-in;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: black;
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 2em;
  left: -0.9em;
  width: 1em;
  height: 1em;
}

.timeline-event-thumbnail {
  -moz-transition: box-shadow 0.5s ease-in 0.1s;
  -o-transition: box-shadow 0.5s ease-in 0.1s;
  -webkit-transition: box-shadow 0.5s ease-in;
  -webkit-transition-delay: 0.1s;
  transition: box-shadow 0.5s ease-in 0.1s;
  color: white;
  font-size: 0.75em;
  background-color: black;
  -moz-box-shadow: inset 0 0 0 0em #16336E;
  -webkit-box-shadow: inset 0 0 0 0em #16336E;
  box-shadow: inset 0 0 0 0em #16336E;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}

</style>
<style scoped>
.cherdack {
  -webkit-box-shadow: 0 0 16px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 9px 16px 0 rgb(0 0 0 / 20%);

}

.btn-shadow {
  -webkit-box-shadow: 0 0 16px rgb(51 51 51 / 20%);
  box-shadow: 0 0 16px rgb(51 51 51 / 20%);
}

.icon-mb {
  margin-bottom: 3px;
  margin-right: 3px;
}

.nav-link {
  color: #333 !important;
  cursor: pointer !important;
}

.btn-primary {
  color: #F0EFED !important;
  cursor: pointer !important;
}

.b {
  color: #333 !important;
}

.b-white {
  color: #F0EFED !important;
}

.dark-green-text {
  color: #16336E !important;
}

.bg-image-1 {
  background: #04A27C url('https://pro-women.ru/local/templates/prowomen/assets/img/index/liderBg.png') bottom left no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-2 {
  background: #16336E url('https://pro-women.ru/local/templates/prowomen/assets/img/index/liderBg.png') bottom left no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-1 h4 {
  color: white;
}

.bg-image-2 h4 {
  color: white;
}

.inactive-my {
  background: rgba(0, 0, 0, 0.096) url('https://pro-women.ru/local/templates/prowomen/assets/img/index/liderBg.png') bottom left no-repeat !important;
  font-weight: 500;
  color: rgba(51, 51, 51, 0.97);
  box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
}

.muted-inactive-my {
  background: rgba(0, 0, 0, 0.017) url('https://pro-women.ru/local/templates/prowomen/assets/img/index/liderBg.png') bottom left no-repeat !important;
  font-weight: 500;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
}

.muted-inactive-my * {
  background: rgba(0, 0, 0, 0.0016) url('https://pro-women.ru/local/templates/prowomen/assets/img/index/liderBg.png') bottom left no-repeat !important;
  color: rgba(51, 51, 51, 0.27) !important;
}

.bg-image-3 {
  background: #eff8f3 100% 100% no-repeat !important;
  background-size: cover; /* Масштабируем фон */
  color: white !important;
  font-weight: 500;
  border-radius: 10px;
}

.bg-image-4 {
  background: linear-gradient(#effdf6, #fff) url('https://pro-women.ru/local/templates/prowomen/assets/img/mentoring/mentoring-manual-item-bg.svg') top right no-repeat !important;
}

.bg-image-5 {
  background: #04A27C url('https://pro-women.ru/local/templates/prowomen/assets/img/index/soviet-bg.svg') top right no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-6 {
  background: #04A27C url('https://pro-women.ru/local/templates/prowomen/assets/img/index/subscribeBg.png') bottom right no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-0 {
  background: linear-gradient(252.6deg, #04A27C -3.65%, #067D61 104.61%) !important;
}

/*  */

.gg-arrow-long-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 50%;
  top: 40%;
  left: 40%;
  right: 10%;
  height: 6px;
}

.gg-arrow-long-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}

@import "https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700,900";
@import "https://fonts.googleapis.com/css?family=Caveat";

@import "./landing_css/theme.css";

@import "./landing_css/all.css";
/* @import "./landing_css/aos.css"; */
@import "./landing_css/cookieconsent.min.css";
@import "./landing_css/magnific-popup.css";
@import "./landing_css/odometer-theme-minimal.css";
@import "./landing_css/prism-okaidia.css";
@import "./landing_css/simplebar.css";
@import "./landing_css/smart_wizard.css";
@import "./landing_css/smart_wizard_theme_arrows.css";
@import "./landing_css/smart_wizard_theme_circles.css";
@import "./landing_css/smart_wizard_theme_dots.css";
@import "./landing_css/swiper.css";
@import "./landing_css/theme.css";
@import "./landing_css/rtl.css";
@import "./landing_css/hover_menu.css";
@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}

/* GENERAL */
@import url('https://fonts.googleapis.com/css?family=Rubik');
.credit {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  color: white;
}

.credit a {
  color: inherit;
}

.swipe {
  width: 40%;
  height: .25rem;
  opacity: .15;
  border-radius: 10px;
  margin: .5rem auto;
}

.icons {
  display: flex;
  margin-top: .5rem;
}

.battery {
  width: .85rem;
  height: .45rem;
}

.network {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6.8px 7.2px 6.8px;
  transform: rotate(135deg);
  margin: .12rem .5rem;
}

/* Middle */
.phone .content {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  width: 100%;
  transform: translateY(5%);
}

label, .toggle {
  height: 3.1rem;
  border-radius: 100px;
}

label {
  width: 100%;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 100px;
  position: relative;
  margin: 1.8rem 0 4rem 0;
  cursor: pointer;
}

.toggle {
  position: absolute;
  width: 50%;
  background-color: #04A27C;
  color: #fff !important;
  box-shadow: 0 2px 15px rgba(0, 0, 0, .15);
  transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.toggle * {
  font-weight: 700;
}

.names {
  font-size: 100%;
  font-weight: bolder;
  width: 75%;
  margin-left: 12.5%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.dark {
  opacity: .5;
}

.mark {
  border-radius: 100px;
  background-color: black;
}

.mark {
  width: 7%;
  margin: auto;
  position: relative;
  height: .25rem;
}

.mark::before {
  content: '';
  position: absolute;
  width: .25rem;
  height: 100%;
  left: -70%;
  opacity: .15;
  background-color: inherit;
}

.mark::after {
  content: '';
  position: absolute;
  width: .25rem;
  height: 100%;
  right: -70%;
  opacity: .15;
  background-color: inherit;
}

/* Bottom */
.skip {
  padding: 1.5rem 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skip p {
  opacity: .55;
  font-size: 90%;
  cursor: pointer;
  transition: all 2s ease;
}

.skip p:hover {
  text-decoration: underline;
}

.fab {
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: transform .5s cubic-bezier(0.19, 1, 0.22, 1);
}

.fab:hover {
  transform: scale(1.2);
}

.arrow {
  width: 40%;
  height: .1rem;
  background-color: black;
}

.arrow::before {
  content: '';
  position: absolute;
  height: .1rem;
  width: 20%;
  background-color: inherit;
  transform: rotate(45deg);
  right: 26%;
  top: 42%;
}

.arrow::after {
  content: '';
  position: absolute;
  height: .1rem;
  width: 20%;
  background-color: inherit;
  transform: rotate(-45deg);
  right: 26%;
  bottom: 42%;
}

/* -------- Switch Styles ------------*/
[type="checkbox"] {
  display: none;
}

/* Toggle */
[type="checkbox"]:checked + .app .toggle {
  transform: translateX(100%);
  background-color: #16336E;
}

[type="checkbox"]:checked + .app .dark {
  opacity: 1;
}

[type="checkbox"]:checked + .app .light {
  opacity: .5;
}

/* App */
[type="checkbox"]:checked + .app .light {
}

[type="checkbox"]:checked + .app .dark {
  color: white;
}

/* Circle */
[type="checkbox"]:checked + .app .crescent {
  transform: scale(1);
  background: #26242E;
}

[type="checkbox"]:checked + .app .circle {
  background: linear-gradient(40deg, #8983F7, #A3DAFB 70%);
}

[type="checkbox"]:checked + .app .main-circle {
  background: linear-gradient(40deg, #8983F7, #A3DAFB 70%);
}

/* Fab */
[type="checkbox"]:checked + .app .fab {
  background-color: #16336E;
}

[type="checkbox"]:checked + .app .arrow,
[type="checkbox"]:checked + .app .mark,
[type="checkbox"]:checked + .app .battery {
  background-color: white;
}

[type="checkbox"]:checked + .app .network {
  border-color: transparent transparent white transparent;
}

[type="checkbox"]:checked + .app .swipe {
  background-color: #16336E;
  opacity: 1;
}

.opacity-bg {
  background-color: rgba(255, 255, 255, 0.95);
}

.height-300 {
  height: 350px;
}

.blob_2 {
  background: #16336E;
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

.blob_1 {
  background: #04A27C;
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
<script>
import AOS from 'aos'
import CookieConsent from 'vue-cookieconsent-component'
import { mapState, mapActions, mapGetters } from 'vuex'
import LinkPersonalCabinet from '@/pages/landing/components/LinkPersonalCabinet'

export default {
  components: { CookieConsent, LinkPersonalCabinet },
  data() {
    return {
      mobile_menu: false,
      menu1_about: '',
      picked: null,
      row: null,
      show: false,
      menu1: false,
      menu1_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString(),
      dateFormatted: '',
      tragectory_individual: false,
      started: false,
      block_expand: {
        ur_profile: false,
        ec_profile: false,
        diff_profile: false
      },
      events: [
        {
          sub: 'Тренинг',
          name: 'Психологический инструментарий в работе налогового консультанта',
          date: '30-31 августа 2021',
          link: 'https://palata-nk.ru/events/trening/trening/'

        },
        {
          sub: 'Курс повышения квалификации',
          name: 'ПРАВО В НАЛОГОВОМ КОНСУЛЬТИРОВАНИИ: АКТУАЛИЗАЦИЯ НАВЫКОВ И ЗНАНИЙ',
          date: '20 сентября — 08 октября 2021',
          link: 'https://palata-nk.ru/events/kurs-povysheniya-kvalifikatsii/pravo-v-nalogovom-konsultirovanii-aktualizatsiya-navykov-i-znaniy/'

        },
        {
          sub: 'Практикум',
          name: 'АРЕНДА: что мы знаем и не знаем о самом распространенном виде сделки (правовые, бухгалтерские, налоговые аспекты)',
          date: '28-30 сентября 2021',
          link: 'https://palata-nk.ru/events/praktikumy/arenda-chto-my-znaem-i-ne-znaem-o-samoy-rasprostranennoy-forme-sdelki-pravovye-bukhgalterskie-nalogo/'

        }
      ],
      quest_org: [
        {
          text: 'Текст ответа на вопрос',
          title: 'Что такое аккредитация образовательной организции?'
        },
        { text: 'Текст ответа на вопрос', title: 'Как пройти аккредитацию?' },
        {
          text: 'Текст ответа на вопрос',
          title: 'Свидетельство об аккредитации'
        },
        { text: 'Текст ответа на вопрос', title: 'Какие нужны документы? ' }
      ],
      quest_attested: [
        {
          text:
            'Повышение квалификации можно пройти в Палате налоговых консультантов или в одной из аккредитованных ПНК образовательных организаций. Формы повышения квалификации: Практикумы; Консультационные семинары; Налоговые видео-семинары; Зачет ситуаций; Психологические тренинги; Участие в проекте «Горячая линия»; Непрерывный курс в образовательной организации',
          title:
            'Заканчивается срок действия квалификационного аттестата, в какой форме можно пройти повышение квалификации?'
        },
        {
          text:
            'В течение 2 лет действия квалификационного аттестата налоговый консультант проходит повышение квалификации в объеме 72 ак. час.',
          title:
            'Сколько часов нужно для продления срока действия квалификационного аттестата?'
        },
        {
          text:
            '1.	Заявление поданное через личный кабинет <br>2.	Согласие на обработку персональных данных. <br>3.	Сканы удостоверения(й) подтверждающие 72ч. повышения квалификации. <br>4.	Подтверждение оплаты членских взносов за последние 3 года, включая текущий год',
          title:
            'Какие документы, необходимые для оформления свидетельства о продлении срока действия квалификационного аттестата '
        },
        {
          text:
            'Да, можно. Если срок действия Вашего квалификационного аттестата истек более года назад, Вам необходимо: <br>1.	подать ходатайство о восстановлении квалификационного аттестата; <br>2.	к ходатайству приложить подтверждение оплаты задолженности по членским взносам за 3 календарных года, включая текущий; <br>3.	пройти повышение квалификации в объеме 72 ак. часов ',
          title:
            'Можно ли восстановить срок действия квалификационного аттестата?'
        },
        {
          text:
            'Закончился срок действия квалификационного аттестата или не оплачен членский взнос',
          title:
            'Не вижу себя в реестре аттестованных налоговых консультантов?'
        }
      ],
      quest_cons: [
        {
          text:
            'В зависимости от выбранной программы, необходимым условием допуска является наличие среднего профессионального (экономического/юридического) или высшего',
          title: 'Требуется ли высшее образование для обучения?'
        },
        {
          text:
            '180, 260  или 260 аккадемических часов требуется для того, чтобы освоить программу',
          title: 'Какое количество академических часов длится обучение?'
        },
        //   {text: 'Налоговый консультант - специалист оказывающий квалифицированное содействие налогоплательщикам (физическим и юридическим лицам) при исполнении налоговых обязанностей.  Для этого налоговый консультант должен обладать знаниями экономики, юриспруденции и собственно налогообложения. Требования к квалификации содержаться в Квалификационном справочнике. В настоящее время Союзом «ПНК» разработан и в декабре 2020 года внесён в Минтруд РФ проект профстандарта «Консультант по налогам и сборам». Деятельность налогового консультанта (консультанта по налогам и сборам) существенно отличается от деятельности представителей других профессий. Это легко увидеть ознакомившись с трудовыми функциями и действиями.' ,title: 'Кто такой налоговый консультант? Чем он отличается от юриста, бухгалтера, аудитора и т.п.?'},
        {
          text:
            'Обучение по программе Палаты налоговых консультантов проводят аккредитованные  образовательные организации.Обучение завершается обязательной сдачей итогового контроля знаний (экзамена). ',
          title: 'Как проходит обучение?'
        }
      ]
    }
  },
  methods: {
    ...mapActions('app', ['GET_LECTORS', 'CREATE_TRAJECTORY']),
    lector_background(url) {
      return `background-image: url(${url})`
    },
    page(page) {
      location.href = page
    },
    get_lectors() {
      this.GET_LECTORS()
    },
    change_tragectory(val) {
      this.started = true
      this.tragectory_individual = val
    },
    expand(block) {
      this.block_expand[block] = !this.block_expand[block]
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      console.log(date)
      if (!date) return null

      const [month, day, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    new_trajectory() {
      this.CREATE_TRAJECTORY({
        about: this.menu1_about,
        meeting_date: this.menu1_date,
        meeting_form: this.row
      }).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  watch: {
    menu1_date(val) {
      this.dateFormatted = this.formatDate(this.menu1_date)
    }
  },
  created() {
    AOS.init()
    this.get_lectors()
  },
  beforeMount() {
    location.href = 'https://pnk.palata-nk.ru/'
  },
  computed: {
    ...mapState('app', ['lectors']),
    computedDateFormatted() {
      return this.formatDate(this.menu1_date)
    },
    lectors_short() {
      return this.lectors.slice(1, 7)
    },
    tragectory1() {
      if (!this.started) {
        return {
          class: 'rounded-lg  mt-5 inactive-my height-300'
        }
      }
      if (this.tragectory_individual) {

        return {
          class: 'rounded-lg shadow mt-5 bg-image-1 height-300'
        }
      } else {
        return {
          class: 'rounded-lg mt-5 muted-inactive-my height-300'
        }
      }

    },
    tragectory2() {
      if (!this.started) {
        return {
          class: 'rounded-lg  mt-5 inactive-my height-300'
        }
      }
      if (!this.tragectory_individual) {
        return {
          class: 'rounded-lg shadow mt-5 bg-image-2 height-300'
        }

      } else {
        return {
          class: 'rounded-lg  mt-5 muted-inactive-my height-300'
        }

      }

    }
  }
}
</script>
