<template>
  <div>
    <h3 v-if="type=='start'" style="color:#333">Информация о наборе групп - обучение</h3>
    <h3 v-if="type=='levelup'" style="color:#333">Информация о мероприятиях по повышению квалификации</h3>
    <h3 v-if="type=='all'" style="color:#333">СПИСОК ОБРАЗОВАТЕЛЬНЫХ ГРУПП</h3>
    <div>
      <v-card>
        <v-simple-table>
          <template slot="default">
            <thead>
              <tr>
                <th class="text-left">Дата начала обучения</th>
                <th class="text-left">Программа</th>
                <th class="text-left">Образовательная организация</th>
                <th class="text-left">Часы</th>
                <th class="text-left">Форма</th>
                <th class="text-left">Стоимость, р.</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in groups" :key="item.id">
                <td>{{ item.date_start }}</td>
                <td>
                  <a v-if="item.program_file" :href="item.program_file" download="" target="_blank">
                    {{ item.program }}
                  </a>
                  <span v-else>{{ item.program }}</span>
                </td>
                <!--                <td>{{ item.city }}</td>-->
                <td>{{ item.org }}</td>
                <td>{{ item.ed_hours }}</td>
                <td>{{ item.form }}</td>
                <td>{{ item.value }}</td>
                <td><v-chip dark @click="path('/education-info?id=' + item.org_id)">Подробнее</v-chip></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
  .v-application a {
       color: #0083dd;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'
// import {auth} from '../../firebase'
import router from '@/router'

export default {
  data() {
    return {
      // form error
      type: 'all',
      search: '',
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      currentPage: 1,
      totalGroups: 0,
      groupsPerPage: 5,
      groups: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'Дата начала обучения',
          value: 'date_start',
          align: 'start',
          sortable: false
        },
        { text: 'Программа', value: 'program' },
        { text: 'Город', value: 'city' },
        { text: 'Образовательная организация', value: 'org' },
        { text: 'Кол-во часов', value: 'ed_hours' },
        { text: 'Форма', value: 'form' },
        { text: 'Стоимость, р.', value: 'value' },
        { text: '', value: 'expand' }

      ],
      listSize: [10, 25, 50, 100],
      pagination:
        {
          descending: !!this.$route.query.desc,
          sortBy: this.$route.query.orderby || 'name',
          rowsPerPage: +this.$route.query.limit || 10,
          page: +this.$route.query.page || 1,
          totalItems: 0
        },
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }]
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  mounted() {
    this.type = this.$route.query.type.split('/')[0] || 'all'
    this.getDataFromApi()
  },
  methods: {
    ...mapActions('app', ['GET_ALL_GROUPS']),
    pageUpdateFunction(newPageNumber) {
      this.currentPage = newPageNumber
      this.getDataFromApi()
    },
    itemsPerPageUpdate(value) {
      this.groupsPerPage = value
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true
      const itemsPerPage = this.groupsPerPage

      this.GET_ALL_GROUPS({ q: this.groupsPerPage, page: this.currentPage, type: this.type })
        .then((response) => {
          this.totalGroups = response.total
          this.groups = response.items
          console.log(this.groups)
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    page(name) {
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    },
    path(url) {
      location.href = url
    },
    downloadProgram(value) {
      console.log(value)
    }
  }
}
</script>
