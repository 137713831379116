<template>
  <div>
    <h3 style="color:#333">Регистрация претендента</h3>
    <div class="overline mb-4">Палата налоговых консультантов</div>
    <div v-if="!isLoading" class="mx-auto" style="max-width: 300px">
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-alert v-if="show_alert" :type="message_type">
          {{ response_message }}
        </v-alert>
        <v-text-field
          v-model="email"
          :rules="[rules.required]"
          :validate-on-blur="false"
          :error="error"
          label="E-mail"
          name="email"
          outlined
          color="#333"
          @keyup.enter="submit"
          @change="resetErrors"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          :error="error"
          :error-messages="errorMessages"
          label="Пароль"
          name="password"
          color="#333"
          outlined
          @change="resetErrors"
          @keyup.enter="submit"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-alert v-if="!$v.password.minLength" :type="message_type">минимально {{ $v.password.$params.minLength.min }} символов</v-alert>
        <v-alert v-else-if="!$v.password.maxLength">максимально {{ $v.password.$params.maxLength.max }} символов</v-alert>
        <v-alert v-else-if="!$v.password.alphaNum">только латинские буквы и цифры</v-alert>
        <v-text-field
          v-model="inn"
          v-mask="'############'"
          :rules="[rules.required]"
          :error="error"
          :error-messages="errorMessages"
          label="ИНН"
          name="inn"
          color="#333"
          outlined
          @change="resetErrors"
          @keyup.enter="submit"
        ></v-text-field>
        <v-alert v-if="inn && $v.inn.$invalid" :type="message_type">Строго 12 цифр</v-alert>
        <v-btn
          :loading="isLoading"
          :disabled="isSignInDisabled"
          block
          x-large
          outlined
          dark
          color="#333"
          @click="register()"
        >Зарегистрироваться</v-btn>

        <br>
        <a style="color:#333" href="/restore">восстановить пароль</a>
        <hr>
        <a style="color:#333" href="/request-org">регистрация образовательной организации</a>

        <div v-if="errorProvider" class="error--text">{{ errorProviderMessages }}</div>
      </v-form>

    </div>

    <v-progress-circular
      v-else
      :width="3"
      color="red"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import router from '@/router'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, alphaNum, email, numeric } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      isSignInDisabled: false,
      // form
      isFormValid: true,
      show_alert: false,
      response_message: '',
      message_type: 'error',
      email: '',
      password: '',
      inn: '',
      error_pass: false,
      // form error
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      // show password field
      showPassword: false,
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }],
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  validations: {
    email: { required, email },
    inn: { required, minLength: minLength(12), maxLength: maxLength(12), numeric: true },
    password: { required, maxLength: maxLength(20), minLength: minLength(5), alphaNum },
  },
  mounted() {
  },
  methods: {
    ...mapActions('app', ['REGISTER']),
    redirect() {
      // console.log("redir")
      location.href = '/login'
    },
    register() {
      if (this.$v.$invalid) {
        this.response_message = 'Пожалуйста, заполните все поля!'
        this.message_type = 'error'
        this.show_alert = true
        return
      }
      this.REGISTER({ email: this.email, password: this.password, inn: this.inn })
        .then((response) => {
          this.response_message = response.message
          this.message_type = response.message_type
          this.show_alert = true
          if (this.message_type !== 'error') {
            setTimeout(() => this.redirect(), 5000)
          }
        })
        .catch((error) => {
          console.log(error)
          this.response_message = 'Произошла ошибка во время обработки запроса'
          this.message_type = 'error'
        })
    },

    page(name) {
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
