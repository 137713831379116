<template>
  <div>
    <h3 style='color:#333'>Личный кабинет</h3>
    <div class="overline mb-4">
      налогового консультанта / претендента
    </div>
    <div v-if="!isLoading" class="mx-auto" style="max-width: 300px">
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-alert v-if="error_pass" type="error">
          {{ error_pass }}
        </v-alert>
        <v-text-field
          v-model="email"
          :rules="[rules.required]"
          :validate-on-blur="false"
          :error="error"
          label="E-mail или номер аттестата"
          name="email"
          outlined
          color="#333"
          @keyup.enter="submit"
          @change="resetErrors"
        ></v-text-field>

        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="showPassword ? 'text' : 'password'"
          :error="error"
          :error-messages="errorMessages"
          label="Пароль"
          name="password"
          color="#333"
          outlined
          @change="resetErrors"
          @keyup.enter="submit"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <p v-if="!loginsuccess" class="small">
          Если вы зарегистрировались, но не знаете пароль - пройдите
          <router-link :to="{name: 'restore'}">"восстановление пароля"</router-link>
        </p>

        <v-btn
          :loading="isLoading"
          :disabled="isSignInDisabled"
          block
          x-large
          outlined
          dark
          color="#333"
          @click="signIn()"
        >Войти
        </v-btn>

        <br>
        <a style='color:#333' href='http://pnk.palata-nk.ru'>
          Вернуться на главную страницу
        </a>
        <hr>

        <a style='color:#333' href='/restore'>
          восстановить пароль
        </a>
        <hr>
        <a style='color:#333' href='/register'>
          регистрация претендента
        </a>

        <div v-if="errorProvider" class="error--text">{{ errorProviderMessages }}</div>
      </v-form>

    </div>

    <v-progress-circular
      v-else
      :width="3"
      color="red"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router'

export default {
  middleware: ['redirectIfAuth'],
  data() {
    return {
      isLoading: false,
      isSignInDisabled: false,
      // form
      isFormValid: true,
      email: '',
      password: '',
      error_pass: false,
      errorMessageResponse: '',
      // form error
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      // show password field
      showPassword: false,
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }],
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      },
      loginsuccess: Number(localStorage.getItem('loginsuccess')) || 0
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('app', ['SIGN_IN']),
    signIn() {
      this.error_pass = false

      if (!this.isFormValid) {
        this.error_pass = 'Заполните все поля'

        return
      }

      this.SIGN_IN({ email: this._updateEmailDelete000(this.email), password: this.password,org:false })
        .then((response) => {
          if (localStorage.uid) {
            if (localStorage.userType === 'org') {
              router.push({ name: 'org' })
            } else {
              router.push({ name: 'consultant' })
            }
          }
          localStorage.setItem('loginsuccess', 1)
        })
        .catch((error) => {
          if (error.response.status === 401)
            this.error_pass = error.response.data.message
        })
    },
    /**
     * Если email начинается с 000, удаляет
     * Это на случай, если консультант входит по номеру аттестата
     * @param email: string
     * @returns {*}: string
     */
    _updateEmailDelete000: (email) => email.slice(0, 3) === '000' ? email.slice(3) : email,
    page(name) {
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
