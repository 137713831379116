<template>
  <div>
    <h3 style='color:#333'>Изменить <br>пароль доступа</h3>
    <div class="overline mb-4"> 
    Палата налоговых консультантов</div>
    <div v-if="!isLoading" class="mx-auto" style="max-width: 300px">
      <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-text-field
            v-model="email"
            :rules="[rules.required]"
            :validate-on-blur="false"
            :error="error"
            label="E-mail или номер аттестата"
            name="email"

            outlined
            :disabled='new_pass || sent'
            color="#333"
            @keyup.enter="submit"
            @change="resetErrors"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            :error="error"
            :error-messages="errorMessages"
            label="Пароль"
            name="password"
            color="#333"
            v-if="new_pass"
            outlined
            @change="resetErrors"
            @keyup.enter="submit"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-template v-if='sent'>
              <v-alert v-if='!message' :color='color' >
                идет загрузка...
              </v-alert>

              <v-alert v-else :color='color' outlined>
                {{message}}
              </v-alert>
              
              
          </v-template>
          <v-btn
            :loading="isLoading"
            :disabled="isSignInDisabled"
            block
            v-else
            x-large
            dark
            outlined
            color="#333"
            @click="restore_password()"
          >Изменить</v-btn>

          <br>
            <a style='color:#333' href='/login'>
             войти
             </a>
              <hr>
             <a style='color:#333' href='/request-first'>
             регистрация налогового консультанта
             </a>
              <hr>
             <a style='color:#333' href='/request-org'>
             регистрация образовательной организации
             </a>

          
          <div v-if="errorProvider" class="error--text">{{ errorProviderMessages }}</div>
      </v-form>
      
    </div>

    <v-progress-circular
      v-else
      :width="3"
      color="red"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { auth } from '../../firebase'
import { signIn,signUp, signInWithProvider } from '../../firebase/helpers/auth'
import { mapState, mapActions } from 'vuex'
import router from '@/router'
export default {
  middleware: ['redirectIfAuth'],
  data() {
    return {
      isLoading: false,
      isSignInDisabled: false,
      // form
      isFormValid: true,
      email: '',
      sent: false,
      message: null,
      password: '',
      // form error
      error: false,
      new_pass: false,
      errorMessages: '',
      errorProvider: false,
      color: 'info',
      errorProviderMessages: '',
      // show password field
      showPassword: true,
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }],
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required'
      }
    }
  },
  mounted() {
    var if_email = this.has_email();
    if (if_email) this.email = if_email.replace('%40','@');

    var new_if = this.has_new();
    if (new_if) this.new_pass = true;

    
  },
  methods: {
    ...mapActions('app', ['CHANGE_PASSWORD','UPDATE_PASSWORD']),
    has_email() {
      const uri = window.location.href.split('?');
      let result = false;
      if (uri.length === 2) {
        const vars = uri[1].split('&');
        let tmp = '';
        vars.forEach((v) => {
          tmp = v.split('=');
          if (tmp.length === 2) {
            if (tmp[0] === 'email') result = tmp[1];
          }
        });
      }
      return result;
    },
    has_new() {
      const uri = window.location.href.split('?');
      let result = false;
      if (uri.length === 2) {
        const vars = uri[1].split('&');
        let tmp = '';
        vars.forEach((v) => {
          tmp = v.split('=');
          if (tmp.length === 2) {
            if (tmp[0] === 'new') result = tmp[1];
          }
        });
      }
      return result;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        this.isSignInDisabled = true
        this.signIn(this.email, this.password)
      }
    },
    restore_password(){
      if(!this.email){
        alert('Введите адрес электронной почты')
        return 
      }


      if (this.new_pass){
        this.UPDATE_PASSWORD({email:this.email, password:this.password})
        .then(response => {
          alert('новый пароль установлен')
          location.href = '/login/'
        })
        .catch(response_err => {
          this.message  = 'Возникла ошибка смены пароля, напишите в чат'
          this.color = 'error'
        });
        this.sent = true

        
        
        return
      }
      else{
        this.CHANGE_PASSWORD(this.email)
        .then(response => {
          this.message = response.message
          this.message += ' если у вас нет доступа или возникла проблема, напишите в чате (в правом нижнем углу)'
          this.color = 'success'
        })
        .catch(response_err => {
          this.message  = 'Пользователь по запросу не найден, напишите нам в чат (в правом нижнем углу)'
          this.color = 'error'
        });

        this.sent = true
      }
      
      
    },
    async signIn(email, password) {
      try {
        const result = await signIn(email, password)
        const { user } = result
        // user is authenticated
      } catch (error) {
        const { code, message } = error
        switch (code) {
        case 'auth/operation-not-allowed':
          this.errorMessages = 'Please enable authentication method on the Firebase Console.'
          break
        case 'auth/user-disabled':
          this.errorMessages = 'This account is disabled, please contact support for more information.'
          break
        default:
          this.errorMessages = 'Email / Password combination invalid.'
        }
        this.error = true
      }
      this.isLoading = false
      this.isSignInDisabled = false
    },
    async signInProvider(provider) {
      this.resetErrors()
      provider.isLoading = true
      this.isSignInDisabled = true
      try {
        const result = await signInWithProvider(provider)
        const token = result.credential.accessToken
        const { user } = result
        // user is authenticated
      } catch (error) {
        const { code, credential, message, email } = error
        this.errorProvider = true
        this.errorProviderMessages = message
      }
      provider.isLoading = false
      this.isSignInDisabled = false
    },
    page(name){
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
