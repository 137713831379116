<template>
  <div>
    <h3 style='color:#333'>ЕДИНЫЙ РЕЕСТР ОБРАЗОВАТЕЛЬНЫХ ОРГАНИЗАЦИЙ</h3>
    <div>
        <v-data-table
        :headers="headers"
        :items="companies"
        :page="currentPage"
        :server-items-length="totalCompanies"
        :items-per-page="companiesPerPage"
        :loading="loading"
        @update:page="pageUpdateFunction"
        @update:items-per-page="itemsPerPageUpdate"
        loading-text="Загрузка... Пожалуйста подождите"
        class="elevation-1"
        @click:row="handleClick"
        ></v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '../../firebase'
import router from '@/router'
export default {
  data() {
    return {
      // form error
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      currentPage: 1,
      totalCompanies: 0,
      companiesPerPage: 5,
      companies: [],
      loading: true,
      options: {},
      headers: [
        {
            text: 'Название организации',
            align: 'start',
            sortable: false,
            value: 'name',
        },
        { text: 'Номер свидетельства об аккредитации', value: 'accred_number' },
        { text: 'Начало аккредитации', value: 'sert_start' },
        { text: 'Окончание аккредитации', value: 'sert_stop' },
        { text: 'Город', value: 'city' },
        { text: 'Регион', value: 'region' }
      ],
      listSize: [10, 25, 50, 100],
      pagination:
        {
          descending: !!this.$route.query.desc,
          sortBy: this.$route.query.orderby || 'name',
          rowsPerPage: +this.$route.query.limit || 10,
          page: +this.$route.query.page || 1,
          totalItems: 0,
        },
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }]
    }
  },
  watch: {
      options: {
        handler () {
          this.getDataFromApi(this.currentPage)
        },
        deep: true,
      },
  },
  mounted() {
    this.getDataFromApi(this.currentPage)
  },
  methods: {
    ...mapActions('app', ['GET_EDUCATIONS']),
    pageUpdateFunction(newPageNumber) {
        this.currentPage = newPageNumber
        let items = this.allCompanies
        console.log(items)
        const total = items.length
        items = items.slice((this.currentPage - 1) * this.companiesPerPage, this.currentPage * this.companiesPerPage)
        this.companies = items
    },
    itemsPerPageUpdate(value){
        this.companiesPerPage = value
        let items = this.allCompanies
        items = items.slice((this.currentPage - 1) * this.companiesPerPage, this.currentPage * this.companiesPerPage)
        this.companies = items
        console.log(this.companies)
    },
      getDataFromApi (page) {
          this.loading = true
          const itemsPerPage = this.companiesPerPage
          
          this.GET_EDUCATIONS()
            .then(response => {
                this.allCompanies = response.items
                let items = this.allCompanies
                console.log(items)
                const total = items.length
                items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                this.totalCompanies = total
                this.companies = items
                this.loading = false
          }).catch(error => {
            console.log(error)
        });
      },
    viewDetails(id){
      location.href='/education-info?id=' + id
    },
    handleClick(value) {
        this.viewDetails(value.id);
        console.log(value.id)
    },
    page(name){
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
