<template>
  <div>
    <h3 style='color:#333'>СПИСОК ОБРАЗОВАТЕЛЬНЫХ ПРОГРАММ</h3>
    <div>
        <v-data-table
        :headers="headers"
        :items="programs"
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        class="elevation-1"
        :expanded.sync="expanded"
        show-expand
        >
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                Описание: {{ item.descriptions }}
            </td>
        </template>
    </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '../../firebase'
import router from '@/router'
export default {
  data() {
    return {
      // form error
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      currentPage: 1,
      totalPrograms: 0,
      programsPerPage: 5,
      programs: [],
      loading: true,
      options: {},
      expanded: [],
      headers: [
        {
            text: 'Название программы',
            align: 'start',
            sortable: false,
            value: 'name',
        },
        { text: 'Уровень', value: 'level_display' },
        { text: '', value: 'data-table-expand' },
      ],
      listSize: [10, 25, 50, 100],
      pagination:
        {
          descending: !!this.$route.query.desc,
          sortBy: this.$route.query.orderby || 'name',
          rowsPerPage: +this.$route.query.limit || 10,
          page: +this.$route.query.page || 1,
          totalItems: 0,
        },
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }]
    }
  },
  watch: {
      options: {
        handler () {
          this.getDataFromApi()
        },
        deep: true,
      },
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    ...mapActions('app', ['GET_ALL_PROGRAMS']),
    pageUpdateFunction(newPageNumber) {
        this.currentPage = newPageNumber
        this.getDataFromApi()
    },
    itemsPerPageUpdate(value){
        this.programsPerPage = value
        this.getDataFromApi()
    },
    getDataFromApi () {
        this.loading = true
        const itemsPerPage = this.programsPerPage
            
        this.GET_ALL_PROGRAMS()
            .then(response => {
                this.totalPrograms = response.total
                this.programs = response.items
                console.log(this.programs)
                this.loading = false
            }).catch(error => {
                console.log(error)
            });
    },
    
    page(name){
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
