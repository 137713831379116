<template>
  <div>
    <h3 style='color:#333'>Информация об организации</h3>
    <div>
        <json-viewer :value="jsonData"></json-viewer>
        <v-btn color=""
            dark
            class="mr-4"
            @click="redir"
        >Назад</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '../../firebase'
import router from '@/router'
//import JsonViewer from '../lib'
export default {
  data() {
    return {
      // form error
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      loading: true,
      jsonData:[],
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }]
    }
  },
  watch: {
      options: {
        handler () {
          this.getDataFromApi(this.currentPage)
        },
        deep: true,
      },
  },
  mounted() {
    this.apiCall(this.$route.query.id)
  },
  methods: {
    ...mapActions('app', ['GET_COMPANY_INFO']),
    redir(){
      location.href='/reestr-company'
    },
    apiCall (id) {
        this.GET_COMPANY_INFO(id)
            .then(response => {
                this.jsonData = response.data
                this.loading = false
            }).catch(error => {
                console.log(error)
            });
    }
  }
}
</script>
