<template>
  <div>
    <h3 style='color:#333'>Подтверждение email</h3>
    <div class="overline mb-4"> 
    Палата налоговых консультантов</div>
    <div v-if="!isLoading" class="mx-auto" style="max-width: 300px">
        <v-alert :type='message_type'  v-if='show_alert'>
          {{response_message}}
        </v-alert>
        <div v-if="errorProvider" class="error--text">{{ errorProviderMessages }}</div>

      <br>
      <a style='color:#333' href='/register'>
        регистрация налогового консультанта
      </a>
      <hr>
      <a style='color:#333' href='/restore'>
        восстановить пароль
      </a>
      <hr>
      <a style='color:#333' href='/request-org'>
        регистрация образовательной организации
      </a>  
    </div>

    <v-progress-circular
      v-else
      :width="3"
      color="red"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '../../firebase'
import router from '@/router'
export default {
  data() {
    return {
      isLoading: true,
      isSignInDisabled: false,
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      show_alert: false,
      response_message:'',
      message_type: 'error',
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }]
    }
  },
  mounted() {
    this.confirm_email(this.$route.query.code)
  },
  methods: {
    ...mapActions('app', ['CONFIRM_EMAIL']),
    redirect(){
      console.log("redir")
      location.href='/login'
    },
    confirm_email(code){
      if (code !== undefined){
        this.CONFIRM_EMAIL({code:code})
        .then(response => {
          console.log(response)
          this.response_message = response.message
          this.message_type = response.message_type
          this.show_alert = true
          this.isLoading = false
          if (this.message_type != 'error'){
            setTimeout(() => this.redirect(), 5000)
          }
        })
        .catch(error => {
          console.log(error)
            this.error_pass = true;
          });
      }
      else{  
        this.response_message = 'Неверный код подтверждения'
        this.message_type = 'error'
        this.show_alert = true
        this.isLoading = false
      }
    },
    
    page(name){
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
