<template>
  <div class="pl-10">

    <div style='width:800px'>
      <h3 style='color:#333'>{{ jsonData['name'] }}</h3>
      <h6>
        Менеджер: {{ jsonData['manager'] }} <br>
        Номер свидетельства об аккредитации: {{ jsonData['accred_number'] }}<br>
        Веб-сайт: <a v-bind:href="'http://' + jsonData['web_page']">
          {{ jsonData['web_page'] }}
        </a>
        <br>
        Телефон: {{ jsonData['phone'] }} <br>
        Менеджер: {{ jsonData['manager'] }} <br>
        E-mail: <a v-bind:href="'mailto:' + jsonData['email']">{{ jsonData['email'] }}</a> <br>
        Адрес: {{ jsonData['adress'] }} <br>
        Город: {{ jsonData['city'] }} <br>
      </h6>
      <br>
      <v-expansion-panels>
        <v-expansion-panel
        >
          <v-expansion-panel-header>
            <h3>Реализуемые программы </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p cols='12' v-for="program in jsonData['programs']" :key="program.id">
              – {{ program.name }} {{ program.level_display }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels>
        <v-expansion-panel
        >
          <v-expansion-panel-header>
            <h3>Информация о наборе групп</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <h5 class="ma-2" v-if="groups['start'].length!=0">Подготовка</h5>
              <v-expansion-panel
                v-for="group in groups['start']"
                :key="group.id"
              >
                <v-expansion-panel-header>
                  {{ group.name }}
                  <v-spacer></v-spacer>
                  Набор до: {{ group.date_start }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pa-2">
                    Стоимость обучения, руб: {{ group.value }} <br>
                    Дата начала занятий: {{ group.date_start }} <br>
                    Форма обучения: {{ group.form }}
                  </v-row>
                  <v-row>
                    <div class="text-left ma-2">
                      <v-btn to="/request-first">Подать заявку</v-btn>
                    </div>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider></v-divider>
            <v-expansion-panels v-if="groups['levelup'].length!=0">
              <h5 class="ma-2">Повышение квалификации</h5>
              <v-expansion-panel
                v-for="group in groups['levelup']"
                :key="group.id"
              >
                <v-expansion-panel-header>
                  <h6>{{ group.program }}
                    <v-spacer></v-spacer>
                    Набор до: {{ group.date_start }}
                  </h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pa-2">
                    Стоимость обучения, руб: {{ group.value }} <br>
                    Дата начала занятий: {{ group.date_start }} <br>
                    Форма обучения: {{ group.form }} <br>
                  </v-row>
                  <div class="mt-3">
                    <a v-if="group.program_file" :href="group.program_file" download="" target="_blank">
                      <v-btn class="mr-1">Скачать программу</v-btn>
                    </a>
                    <v-btn :to="'/request-second?id=' + jsonData.id + '&group=' + group.id">Подать заявку</v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {auth} from '../../firebase'
import router from '@/router'
//import JsonViewer from '../lib'
export default {
  data() {
    return {
      // form error
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      loading: true,
      jsonData: [],
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }],
      groups: {'levelup': [], 'start': []}
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi(this.currentPage)
      },
      deep: true,
    },
  },
  mounted() {
    this.apiCall(this.$route.query.id)
    this.getGroups(this.$route.query.id, 'start', 0)
    this.getGroups(this.$route.query.id, 'levelup', 1)
  },
  methods: {
    ...mapActions('app', ['GET_EDUCATION_INFO', 'GET_ORG_GROUPS']),
    redir() {
      location.href = '/reestr-company'
    },
    apiCall(id) {
      this.GET_EDUCATION_INFO(id)
        .then(response => {
          this.jsonData = response.data
          this.loading = false
        }).catch(error => {
        console.log(error)
      });
    },
    getGroups(id, level, level_id) {
      this.GET_ORG_GROUPS({id: id, level: level_id}).then(response => {
        this.groups[level] = response.items
        console.log(this.groups)
      }).catch(error => {
        console.log(error)
      });
    }
  }
}
</script>
