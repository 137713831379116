<template>
  <div>
    <h3 style='color:#333'>ЕДИНЫЙ РЕЕСТР АТТЕСТОВАННЫХ КОНСУЛЬТАНТОВ, ЧЛЕНОВ ПНК</h3>
    <div>
        <v-data-table
        :headers="headers"
        :items="consultants"
        :page="currentPage"
        :server-items-length="totalConsultants"
        :items-per-page="consultantsPerPage"
        :loading="loading"
        @update:page="pageUpdateFunction"
        @update:items-per-page="itemsPerPageUpdate"
        loading-text="Загрузка... Пожалуйста подождите"
        class="elevation-1"
        @click:row="handleClick"
        ></v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { auth } from '../../firebase'
import router from '@/router'
export default {
  data() {
    return {
      // form error
      error: false,
      errorMessages: '',
      errorProvider: false,
      errorProviderMessages: '',
      currentPage: 1,
      totalConsultants: 0,
      consultantsPerPage: 10,
      consultants: [],
      allConsultants: [],
      loading: true,
      options: {},
      headers: [
        {
            text: 'Номер аттестата',
            align: 'start',
            sortable: false,
            value: 'attestat_number',
        },
        { text: 'ФИО', value: 'fio' },
        { text: 'Город', value: 'city' },
        { text: 'Регион', value: 'region' },
        { text: 'Начало действия аттестата', value: 'sert_start' },
        { text: 'Окончание действия аттестата', value: 'sert_end' },
        { text: 'Диплом', value: 'diplom' },
        { text: 'Образовательная организация', value: 'education' }
      ],
      pagination:
        {
          descending: !!this.$route.query.desc,
          sortBy: this.$route.query.orderby || 'name',
          rowsPerPage: +this.$route.query.limit || 10,
          page: +this.$route.query.page || 1,
          totalItems: 0,
        },
      providers: [{
        id: 'google',
        label: 'Google',
        isLoading: false
      }]
    }
  },
  watch: {
      options: {
        handler () {
          this.getDataFromApi(this.currentPage)
        },
        deep: true,
      },
  },
  mounted() {
    this.getDataFromApi(this.currentPage)
  },
  methods: {
    ...mapActions('app', ['GET_CONSULTANTS']),
    pageUpdateFunction(newPageNumber) {
        this.currentPage = newPageNumber
        this.getDataFromApi()
    },
    itemsPerPageUpdate(value){
        this.consultantsPerPage = value
        this.getDataFromApi()
        //console.log(this.consultants)
    },
      getDataFromApi () {
          this.loading = true
          const itemsPerPage = this.consultantsPerPage
          
          this.GET_CONSULTANTS({q:this.consultantsPerPage, page:this.currentPage})
            .then(response => {
                this.totalConsultants = response.total
                this.consultants = response.items
                this.loading = false
          }).catch(error => {
            console.log(error)
        });
      },
    viewDetails(id){
      location.href='/company-info?id=' + id
    },
    handleClick(value) {
        this.viewDetails(value.id);
        console.log(value.id)
    },
    page(name){
      router.push(name)
    },
    resetErrors() {
      this.error = false
      this.errorMessages = ''
      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  }
}
</script>
